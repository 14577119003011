import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "is-size-3 is-family-secondary has-text-info has-text-left" }
const _hoisted_4 = {
  class: "active-restaurant-select",
  style: {"background-color":"#f0ba30","padding":"1rem","border-radius":"0.5rem","z-index":"1"}
}
const _hoisted_5 = {
  for: "active-restaurant",
  class: "is-denim is-bold mr-3"
}
const _hoisted_6 = ["v-model"]
const _hoisted_7 = { class: "filter-container" }
const _hoisted_8 = { class: "filter-row chip-selector" }
const _hoisted_9 = {
  key: 0,
  class: "filter-row"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 0,
  class: "filter-row month-selector"
}
const _hoisted_12 = { class: "selected-month" }
const _hoisted_13 = {
  key: 1,
  class: "filter-row weekly-selector"
}
const _hoisted_14 = { class: "week-range-select" }
const _hoisted_15 = { id: "date-range-picker" }
const _hoisted_16 = { class: "items" }
const _hoisted_17 = {
  key: 0,
  class: "data-dashboard",
  style: {"margin-top":"15px"}
}
const _hoisted_18 = { class: "total-returns-container" }
const _hoisted_19 = {
  key: 0,
  class: "is-full mx-5"
}
const _hoisted_20 = {
  key: 1,
  class: "dashboard-row"
}
const _hoisted_21 = {
  key: 0,
  class: "group-returns-container"
}
const _hoisted_22 = { class: "return-rates-container" }
const _hoisted_23 = {
  key: 2,
  class: "is-full mx-5"
}
const _hoisted_24 = {
  key: 3,
  class: "dashboard-row"
}
const _hoisted_25 = { class: "returns-breakdown-container" }
const _hoisted_26 = { class: "orders-breakdown-container" }
const _hoisted_27 = {
  key: 4,
  class: "not-enough-data-container"
}
const _hoisted_28 = {
  key: 5,
  class: "has-text-centered"
}
const _hoisted_29 = { class: "is-full is-centered is-bold is-denim" }
const _hoisted_30 = {
  class: "button is-medium is-rounded is-sunflower-back is-denim is-family-secondary",
  href: "mailto:info@friendlier.ca?CC=michelle.md@friendlier.com&Subject=Improving%20Analytics&Body=Let%20us%20know%20which%20metrics%20would%20be%20useful%20to%20your%20business%21",
  style: {"margin-block":"5%","text-align":"center"},
  target: "_blank"
}
const _hoisted_31 = { class: "equivalency-container" }
const _hoisted_32 = { key: 1 }

import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import type { Ref } from "vue";

import { useRouter } from "vue-router";
import html2pdf from "html2pdf.js";

import { Outlet } from "@/models";

import { getUser, useAnalytics } from "@/composables";
import { clientStore, userStore, permissionStore, collectionStore } from "@/store";
import {
  Loading,
  NotPermitted,
  UnderCons,
  TotalReturns,
  Equivalencies,
  ReturnRates,
  UsersReport,
  CyclesReport,
  GroupReturns,
  ReturnsBreakdown,
  OrdersBreakdown,
} from "@/components";
import { logError } from "@/utils";
/***** view controllers ******/

export default /*@__PURE__*/_defineComponent({
  __name: 'Analytics',
  setup(__props) {

const { t } = useI18n();
/***** view controllers ******/
const loading: Ref<boolean> = ref(true);
const outletLoading: Ref<boolean> = ref(false);
const permitted = ref(false);
const tab = ref("total");

const router = useRouter();
const analytics = useAnalytics();

/******* user data *********/
const isUnderConstruction = ref(false);

/******* user data *********/
const { user } = getUser(); //unused

/******* restaurant lists *******/
const restaurants: Ref<Outlet[]> = ref([]);
const activeRestaurant: Ref<Outlet | undefined> = ref();

const groupChip = ref("current");
const rangeChip = ref("monthly");

const isMultiOutlet: Ref<boolean> = ref(false);

const enoughData = ref(true);
const premiumData = ref(false);

const includeGroup = ref(false);

//Dates
const datesMap: Ref<{ [key: string]: string[] }> = ref({});
const selectedMonth: Ref<string | null> = ref(null);
const selectedWeeks: Ref<string[]> = ref([]);

const avaliableWeeks: Ref<string[]> = ref([]);
const startWeek: Ref<string | undefined> = ref();
const startWeekOptions: Ref<string[]> = ref([]);
const endWeek: Ref<string | undefined> = ref();
const endWeekOptions: Ref<string[]> = ref([]);

const monthOptions: Ref<string[]> = ref([]);

onMounted(async () => {
  document.title = "Friendlier | Analytics";
  try {
    await clientStore.initialize();
    await userStore.initialize();
    await collectionStore.initialize();
    await permissionStore.initialize(userStore.accountTier);

    try {
      if (!userStore.portalApproved) {
        router.push({ path: "/business-pending" });
        return;
      }
    } catch (err: any) {
      logError(`Analytics.onMounted: Failed to check for portal approval. ${err.toString()}`);
      router.push("login");
    }

    try {
      if (permissionStore.can("viewAnalytics")) {
        permitted.value = true;
      } else {
        loading.value = false;
        return;
      }
    } catch (err: any) {
      logError(`Order.getData: Failed to apply permissions. ${err.toString()}`);
    }

    try {
      if (permissionStore.can("viewDetailedAnalytics")) {
        premiumData.value = true;
      }
    } catch (err: any) {
      logError(`Order.getData: Failed to apply permissions. ${err.toString()}`);
    }

    restaurants.value = await Outlet.getUserOutlets(userStore.user!);
    activeRestaurant.value = restaurants.value[0];

    try {
      const analyticsData = await analytics.getAnalytics();
      if (analyticsData && typeof analyticsData.isUnderConstruction !== "undefined") {
        isUnderConstruction.value = analyticsData.isUnderConstruction;
      }

      if (analyticsData && analyticsData.constructionBypass.includes(userStore.uid)) {
        isUnderConstruction.value = false;
      }
    } catch (error) {
      console.error("Error fetching analytics:", error);
    }
    loading.value = false;
  } catch (err: any) {
    logError(`Analytics.onMounted: Failed to setup analytics page. ${err.toString()}`);
  }
});

// NOTE define need - centralize
watch(user, () => {
  if (!user.value) {
    router.push({ name: "Login" });
  }
});

/**
 * React to changes in the active restaurant and update numbers accordingly
 * @state {Boolean} loading
 * @state {Boolean} noData
 *
 */
watch(activeRestaurant, async () => {
  try {
    outletLoading.value = true;
    console.log(activeRestaurant.value!.outletGroup);
    isMultiOutlet.value = activeRestaurant.value!.outletGroup ? true : false;

    let weeks = await activeRestaurant.value!.getAnalyticEntries();
    avaliableWeeks.value = weeks;

    enoughData.value = avaliableWeeks.value.length >= 4;

    if (!startWeek.value || startWeek.value < avaliableWeeks.value[0]) {
      startWeek.value = avaliableWeeks.value[0];
    }

    if (!endWeek.value || endWeek.value > avaliableWeeks.value[avaliableWeeks.value.length - 1]) {
      endWeek.value = avaliableWeeks.value[avaliableWeeks.value.length - 1];
    }

    if (avaliableWeeks.value.length > 0) {
      updateMonthOptions();
    }

    if (rangeChip.value === "weekly") {
      selectWeeks();
    } else if (rangeChip.value === "monthly" && !selectedMonth.value) {
      selectedMonth.value = monthOptions.value[monthOptions.value.length - 1];
    }

    outletLoading.value = false;
  } catch (err: any) {
    logError(`Analytics.watchActiveRestaurant: Failed to update for new restaurant. ${err.toString()}`);
  }
});

/**
 * Watch for changes in the startWeek value, trim the ending options to not allow inverse time range
 * @state {array} endWeekOptions
 * @state {string} startWeek
 */
watch(startWeek, () => {
  if (!endWeek.value || !startWeek.value) return;
  if (startWeek.value > endWeek.value) {
    endWeek.value = startWeek.value;
  }
  endWeekOptions.value = avaliableWeeks.value.filter((e) => e >= startWeek.value!);
});

/**
 * Watch for changes in the endWeek value, trim the starting options to not allow inverse time range
 * @state {array} startWeekOptions
 * @state {string} endWeek
 */
watch(endWeek, () => {
  if (!endWeek.value || !startWeek.value) return;
  if (endWeek.value < startWeek.value) {
    endWeek.value = startWeek.value;
  }
  startWeekOptions.value = avaliableWeeks.value.filter((e) => e <= endWeek.value!);
});

watch(selectedMonth, async (newVal, oldVal) => {
  if (newVal && newVal !== oldVal && rangeChip.value === "monthly") {
    selectedWeeks.value = datesMap.value[newVal];
  }
});

watch(groupChip, async (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    if (newVal === "current") {
      includeGroup.value = false;
    } else {
      includeGroup.value = true;
    }
  }
});

/**
 * Handles the click event of a menu item.
 *
 * @param {string} chipType - The type of the chip.
 * @param {string} chip - The selected chip.
 */
function onMenuItemClick(chipType: string, chip: string) {
  if (chipType === "group") {
    groupChip.value = chip;
  } else if (chipType === "dateRange") {
    rangeChip.value = chip;
    if (chip === "monthly") {
      selectedWeeks.value = datesMap.value[selectedMonth.value!];
    }
  }
}

/**
 * Changes the active restaurant based on the selected value.
 *
 * @param {Event} e - The event object.
 * @returns {void}
 */
async function changeActiveRestaurant(e: Event): Promise<void> {
  for (let res in restaurants.value) {
    let currentTarget = e.target as HTMLSelectElement;
    if (restaurants.value[res].displayName == currentTarget.value) {
      activeRestaurant.value = restaurants.value[res];
      clientStore.setActiveClientByPlaceID(restaurants.value[res].placeID);
      break;
    }
  }
}

/**
 * Selects the weeks within the specified range.
 *
 * @async
 * @function selectWeeks
 * @returns {Promise<void>} A promise that resolves when the weeks are selected.
 * @throws {Error} If there is an error selecting the weeks.
 */
function selectWeeks(): void {
  outletLoading.value = true;
  selectedWeeks.value = [];
  try {
    for (let week of avaliableWeeks.value) {
      if (week >= startWeek.value! && week <= endWeek.value!) {
        selectedWeeks.value.push(week);
      }
    }
    outletLoading.value = false;
  } catch (err: any) {
    logError(`analytics.selectWeeks: Failed to select weeks. ${err.toString()}`);
  }
}

/**
 * Updates the month options for analytics.
 *
 * @throws {Error} If there is an error while updating the month options.
 */
function updateMonthOptions() {
  try {
    datesMap.value = {};
    avaliableWeeks.value.sort();

    startWeekOptions.value = avaliableWeeks.value;
    endWeekOptions.value = avaliableWeeks.value;

    avaliableWeeks.value.forEach((dateStr) => {
      const date = new Date(dateStr);
      const day = date.getUTCDate();
      const monthIndex = date.getUTCMonth();
      const year = date.getUTCFullYear();

      const monthYearKey = `${date.toLocaleString("default", {
        month: "long",
      })} ${year}`;

      if (year > 2023 || (year === 2023 && monthIndex >= 9)) {
        if (!(monthYearKey in datesMap.value)) {
          datesMap.value[monthYearKey] = [];
        }

        if (day > 3) {
          datesMap.value[monthYearKey].push(dateStr);
        }
      }
    });

    for (const [_, items] of Object.entries(datesMap.value)) {
      if (items.length < 1) continue;
      const lastDate = new Date(items[items.length - 1]);
      const nextMonthFirstDate = new Date(Date.UTC(lastDate.getUTCFullYear(), lastDate.getUTCMonth() + 1, 1, 0, 0, 0, 0));

      for (let i = 0; i < 3; i++) {
        let nextDate = new Date(nextMonthFirstDate.toUTCString());
        nextDate.setUTCDate(nextDate.getUTCDate() + i);
        const nextDateStr = nextDate.toISOString().split("T")[0];
        if (avaliableWeeks.value.includes(nextDateStr)) {
          items.push(nextDateStr);
        }
      }
    }

    const monthYearPairs: any[] = Array.from(Object.entries(datesMap.value));

    const sortedValidMonths = monthYearPairs
      .filter(([_, dates]) => dates.length >= 1)
      .sort((a: any[], b: any[]) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
      .map(([monthYear, _]) => monthYear);

    monthOptions.value = sortedValidMonths;

    if (monthOptions.value.length > 0 && rangeChip.value != "monthly")
      selectedMonth.value = monthOptions.value[monthOptions.value.length - 1];

    if (rangeChip.value === "monthly" && selectedMonth.value) {
      let flag = false;
      for (let key of monthOptions.value) if (key === selectedMonth.value) flag = true;
      if (!flag) selectedMonth.value = monthOptions.value[monthOptions.value.length - 1];
    }
  } catch (err: any) {
    logError(`analytics.updateMonthOptions: Failed to update month options. ${err.toString()}`);
  }
}

const exportAsPDF = async () => {
  const element: HTMLElement | null = document.querySelector(".items");
  const itemElement: HTMLElement | null = document.querySelector(".item");
  const premiumDataElement: HTMLElement | null = document.querySelector(".premium-data");

  if (itemElement) {
    itemElement.style.display = "none";
  }

  let originalMarginBottom: string;
  if (premiumDataElement) {
    originalMarginBottom = premiumDataElement.style.marginBottom;
    premiumDataElement.style.marginBottom = "80px";
  }

  let options = {
    margin: [0, 15, 10, 15],
    filename: "analytics-export.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 3 },
    jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
  };

  await html2pdf(element, options);
};

return (_ctx: any,_cache: any) => {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_select = _resolveComponent("v-select")!

  return (loading.value)
    ? (_openBlock(), _createBlock(_unref(Loading), { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!permitted.value)
          ? (_openBlock(), _createBlock(_unref(NotPermitted), { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (isUnderConstruction.value)
                ? (_openBlock(), _createBlock(_unref(UnderCons), { key: 0 }))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("span", _hoisted_2, [
                      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("analytics.an")), 1),
                      _createElementVNode("span", _hoisted_4, [
                        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("active-business")), 1),
                        _createElementVNode("select", {
                          id: "active-restaurant",
                          "v-model": activeRestaurant.value,
                          onchange: changeActiveRestaurant
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(restaurants.value, (res) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: res.placeID
                            }, _toDisplayString(res.displayName), 1))
                          }), 128))
                        ], 8, _hoisted_6)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        (!isMultiOutlet.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createVNode(_component_v_chip, {
                                class: _normalizeClass({ active: groupChip.value === 'current' }),
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (onMenuItemClick('group', 'current'))),
                                style: {"font-weight":"900","margin-right":"10px"}
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode(" Current Unit ")
                                ])),
                                _: 1
                              }, 8, ["class"]),
                              _createVNode(_component_v_chip, {
                                class: _normalizeClass({ active: groupChip.value === 'total' }),
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (onMenuItemClick('group', 'total'))),
                                style: {"font-weight":"900"}
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode(" Total Organization ")
                                ])),
                                _: 1
                              }, 8, ["class"])
                            ])),
                        _createElementVNode("div", null, [
                          _createVNode(_component_v_chip, {
                            class: _normalizeClass({ active: rangeChip.value === 'weekly' }),
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (onMenuItemClick('dateRange', 'weekly'))),
                            style: {"font-weight":"900","margin-right":"10px"}
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode(" Weekly ")
                            ])),
                            _: 1
                          }, 8, ["class"]),
                          _createVNode(_component_v_chip, {
                            class: _normalizeClass({ active: rangeChip.value === 'monthly' }),
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (onMenuItemClick('dateRange', 'monthly'))),
                            style: {"font-weight":"900"}
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" Monthly ")
                            ])),
                            _: 1
                          }, 8, ["class"])
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("button", {
                            onClick: exportAsPDF,
                            class: "button"
                          }, "Export as PDF")
                        ])
                      ]),
                      (rangeChip.value == 'monthly')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, _toDisplayString(selectedMonth.value || "Select a Month"), 1),
                            _createVNode(_component_v_select, {
                              label: "Select Month",
                              modelValue: selectedMonth.value,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedMonth).value = $event)),
                              items: monthOptions.value,
                              style: {"max-width":"300px"},
                              "hide-details": "auto"
                            }, null, 8, ["modelValue", "items"])
                          ]))
                        : _createCommentVNode("", true),
                      (rangeChip.value == 'weekly')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _cache[13] || (_cache[13] = _createElementVNode("label", {
                              class: "",
                              for: "date-range-picker"
                            }, "Select a Range:", -1)),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("span", null, [
                                  _cache[11] || (_cache[11] = _createElementVNode("label", { for: "end-week-picker" }, "Start:", -1)),
                                  _withDirectives(_createElementVNode("select", {
                                    class: "select",
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((startWeek).value = $event))
                                  }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(startWeekOptions.value, (week) => {
                                      return (_openBlock(), _createElementBlock("option", { key: week }, _toDisplayString(week), 1))
                                    }), 128))
                                  ], 512), [
                                    [_vModelSelect, startWeek.value]
                                  ])
                                ]),
                                _createElementVNode("span", null, [
                                  _cache[12] || (_cache[12] = _createElementVNode("label", { for: "end-week-picker" }, "End:", -1)),
                                  _withDirectives(_createElementVNode("select", {
                                    class: "select",
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((endWeek).value = $event))
                                  }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(endWeekOptions.value, (week) => {
                                      return (_openBlock(), _createElementBlock("option", { key: week }, _toDisplayString(week), 1))
                                    }), 128))
                                  ], 512), [
                                    [_vModelSelect, endWeek.value]
                                  ])
                                ])
                              ]),
                              _createElementVNode("button", {
                                class: "button",
                                onClick: selectWeeks
                              }, "Go!")
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      (!outletLoading.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_unref(TotalReturns), {
                                outlet: activeRestaurant.value!,
                                weeks: selectedWeeks.value,
                                group: includeGroup.value
                              }, null, 8, ["outlet", "weeks", "group"])
                            ]),
                            (premiumData.value && enoughData.value)
                              ? (_openBlock(), _createElementBlock("hr", _hoisted_19))
                              : _createCommentVNode("", true),
                            (premiumData.value && enoughData.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                  _createElementVNode("div", null, [
                                    _createVNode(_unref(UsersReport), {
                                      outlet: activeRestaurant.value!,
                                      weeks: selectedWeeks.value,
                                      padding: "15px 15px 0px 15px",
                                      group: includeGroup.value
                                    }, null, 8, ["outlet", "weeks", "group"]),
                                    _createVNode(_unref(CyclesReport), {
                                      outlet: activeRestaurant.value!,
                                      weeks: selectedWeeks.value,
                                      padding: "0px 15px 15px 15px",
                                      group: includeGroup.value
                                    }, null, 8, ["outlet", "weeks", "group"])
                                  ]),
                                  (isMultiOutlet.value)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                        _createVNode(_unref(GroupReturns), {
                                          outlet: activeRestaurant.value!,
                                          weeks: selectedWeeks.value,
                                          group: includeGroup.value
                                        }, null, 8, ["outlet", "weeks", "group"])
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", _hoisted_22, [
                                    _createVNode(_unref(ReturnRates), {
                                      outlet: activeRestaurant.value!,
                                      weeks: selectedWeeks.value,
                                      group: includeGroup.value
                                    }, null, 8, ["outlet", "weeks", "group"])
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (premiumData.value && enoughData.value)
                              ? (_openBlock(), _createElementBlock("hr", _hoisted_23))
                              : _createCommentVNode("", true),
                            (premiumData.value && enoughData.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                  _createElementVNode("div", _hoisted_25, [
                                    _createVNode(_unref(ReturnsBreakdown), {
                                      outlet: activeRestaurant.value!,
                                      weeks: selectedWeeks.value,
                                      group: includeGroup.value
                                    }, null, 8, ["outlet", "weeks", "group"])
                                  ]),
                                  _createElementVNode("div", _hoisted_26, [
                                    _createVNode(_unref(OrdersBreakdown), {
                                      outlet: activeRestaurant.value!,
                                      weeks: selectedWeeks.value,
                                      group: includeGroup.value
                                    }, null, 8, ["outlet", "weeks", "group"])
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (!loading.value && !enoughData.value && premiumData.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_27, _cache[14] || (_cache[14] = [
                                  _createElementVNode("p", { style: {"text-align":"center"} }, " Sorry! Not enough data - please wait until at least a month of data has been collected. ", -1)
                                ])))
                              : _createCommentVNode("", true),
                            (!loading.value && !premiumData.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                  _createElementVNode("h1", _hoisted_29, _toDisplayString(_unref(t)("analytics.more-details")), 1),
                                  _createElementVNode("a", _hoisted_30, _toDisplayString(_unref(t)("analytics.click-here")), 1)
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_31, [
                              _createTextVNode(_toDisplayString(_unref(t)("analytics.click-here")) + " ", 1),
                              _createVNode(_unref(Equivalencies), {
                                outlet: activeRestaurant.value!,
                                weeks: selectedWeeks.value,
                                group: includeGroup.value
                              }, null, 8, ["outlet", "weeks", "group"])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_32, [
                            _createVNode(_unref(Loading))
                          ]))
                    ])
                  ]))
            ], 64))
      ], 64))
}
}

})