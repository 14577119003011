<template>
  <DataPanel
    type="user-report"
    id="active-users"
    fontColour="ocean"
    bgColour="transparent"
    :data="[
      {
        value: {
          total: active,
          repeat: repeat,
          new: newUsers,
        },
        title: { text: 'Average Weekly Users', colour: 'denim' },
      },
    ]"
    :loading="loading"
    :failed="failed"
    :padding="props.padding"
  />
</template>
<script setup lang="ts">
import { defineProps, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { DataPanel } from "@/components";
import { logError } from "@/utils";

import { Outlet } from "@/models";

const props = defineProps({
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
  padding: {
    type: String,
    default: "",
    required: false,
  },
});

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const active: Ref<number> = ref(0);
const repeat: Ref<number> = ref(0);
const newUsers: Ref<number> = ref(0);

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("usersReport: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;

  try {
    let usersReport = await props.outlet!.getUsersReport(props.weeks, props.group);
    active.value = usersReport.active;
    repeat.value = usersReport.repeat;
    newUsers.value = usersReport.new;
  } catch (err: any) {
    failed.value = true;
    logError(`usersReport: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.data-delim {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  margin: auto;
  height: fit-content;

  .divider {
    flex-grow: 2;
    background-color: $cloud;
    min-height: 20px;
    min-width: 1px;
  }

  .text {
    color: $cloud;
    flex-grow: 1;
  }
}

@media screen and (max-width: 768px) {
  .data-delim {
    display: none;
  }
}
</style>
