<template>
  <div class="subtitle">{{ $t("checkoutRestaurantDetails.restaurant-details") }}</div>
  <div class="detail-container">
    <Loading v-if="clientStore.userClients == null || clientStore.userClients == undefined" medium />

    <div class="detail-row" v-if="clientStore.userClients.length > 0">
      <ClientSelect :editable="editable" />
    </div>
    <div class="detail-row" v-else>
      <label for="companySelector" class="no-select">{{ $t("checkoutRestaurantDetails.name") }}</label>
      <input disabled id="companySelector" class="input-field" type="text" :value="clientStore.activeClient.data.displayName" />
    </div>

    <div class="detail-row" v-if="clientStore.activeClient">
      <label for="restaurant-name">{{ $t("checkoutRestaurantDetails.region") }}</label>
      <input disabled id="restaurant-name" class="input-field" type="text" :value="clientStore.activeClient.data.region" />
    </div>

    <div class="detail-row" v-if="clientStore.activeClient && deliveryDate">
      <label for="restaurant-name">{{ $t("checkoutRestaurantDetails.estimated-delivery") }}</label>
      <input disabled id="restaurant-name" class="input-field" type="text" :value="formatDate(deliveryDate)" />
    </div>
    <div class="detail-row" v-if="clientStore.activeClient && !deliveryDate">
      <label for="restaurant-name">{{ $t("checkoutRestaurantDetails.estimated-delivery") }}</label>
      <SmallLoading />
    </div>

    <div class="detail-row" v-if="clientStore.activeClient">
      <label for="restaurant-address">{{ $t("checkoutRestaurantDetails.address") }}</label>
      <input
        disabled
        id="restaurant-address"
        class="input-field"
        type="text"
        :value="clientStore.activeClient.data.streetAddress"
      />
    </div>

    <div class="warning" v-if="editable" @click.prevent="toggleHelpModal">{{ $t("checkoutRestaurantDetails.help-modal") }}</div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { toggleHelpModal } from "@/components/sidebarFolder/state";
// import { getDeliveryDate } from "@/utils";
import { Loading, ClientSelect, SmallLoading } from "@/components";
import { clientStore } from "@/store";
import { ordersAPI } from "@/composables";

const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
  deliveryDate: {
    type: Date,
    default: null,
  },
  activeRestaurant: {
    type: Object,
    default: null,
  },
});

const emits = defineEmits(["deliveryDate", "outletChange"]);
const deliveryDate = ref();

async function setup() {
  if (!props.editable) {
    await clientStore.setActiveClientByPlaceID(props.activeRestaurant["placeID"]);
  }
  updateDeliveryDate();
}

onMounted(() => {
  setup();
});

watch(
  () => clientStore.activeClient,
  async (newVal, oldVal) => {
    console.log(newVal ? newVal["data"].placeID : undefined, oldVal ? oldVal["data"].placeID : undefined);
    updateDeliveryDate();
    if (newVal && oldVal && newVal["data"].placeID !== oldVal["data"].placeID) emits("outletChange");
  },
  { immediate: true }
);

async function updateDeliveryDate() {
  deliveryDate.value = props.deliveryDate;
  if (!deliveryDate.value) {
    deliveryDate.value = await ordersAPI.getDeliveryDate(clientStore.activeClient["data"].placeID);
    emits("deliveryDate", deliveryDate.value);
  }
}

function formatDate(date) {
  if (typeof date == "number") {
    date = new Date(date);
  }
  return date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}
</script>
<style lang="scss" scoped>
.small-loading-container {
  margin-top: 0% !important;
}
</style>
