<template>
  <Loading v-if="loading || totalPrice == 0" />
  <template v-else>
    <div class="subtitle">
      {{ $t("orderDetailsTable.order-details") }}
    </div>
    <div v-if="(!editable && activeOrder.purchase_order) || purchaseOrder" class="px-3">
      <div class="is-flex">
        <p class="is-bold">Purchase Order:</p>
        <p class="pl-5">{{ purchaseOrder ?? activeOrder.purchase_order }}</p>
      </div>
      <hr v-if="(!editable && activeOrder.purchase_order) || purchaseOrder" class="is-full" />
    </div>
    <span v-if="!editable" class="variable-notice">{{ $t("orderDetailsTable.var-notice") }}</span>

    <div class="detail-container">
      <Loading v-if="quantities == null || quantities == undefined" medium />

      <div class="product-container" v-for="product in localProducts" :key="product.boxIdTag">
        <div class="product-row columns">
          <img :src="product.imageURL" class="column is-3" />
          <div class="column is-4 is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-flex-start">
            <p class="name">{{ product.boxIdTag }}</p>
            <p class="quantity">
              {{ $t("orderDetailsTable.qty") }} {{ product.containersPerCase * quantities[product.boxIdTag] }}
            </p>
          </div>

          <div class="column is-5 is-flex is-flex-direction-column is-align-items-flex-end is-justify-content-flex-start">
            <p class="name">
              CA${{ formatPrice((product.unitPrice / 100) * product.containersPerCase * quantities[product.boxIdTag]) }}
            </p>
            <p class="quantity">
              CA${{ formatPrice(product.unitPrice / 100) }}
              {{ $t("orderDetailsTable.each") }}
            </p>
          </div>
        </div>

        <div class="deposit-row columns">
          <img :src="AndDeposit" class="column is-3" />
          <div class="column is-4 is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-flex-start">
            <p class="name">{{ product.boxIdTag }}-D</p>
            <p class="quantity">
              {{ $t("orderDetailsTable.qty") }} {{ product.containersPerCase * quantities[product.boxIdTag] }},
              {{ $t("orderDetailsTable.tax-included") }}
            </p>
          </div>
          <div class="column is-5 is-flex is-flex-direction-column is-align-items-flex-end is-justify-content-flex-start">
            <p class="name">
              CA${{ formatPrice((product.depositPrice / 100) * product.containersPerCase * quantities[product.boxIdTag]) }}
            </p>
            <p class="quantity">
              CA${{ formatPrice(product.depositPrice / 100) }}
              {{ $t("orderDetailsTable.each") }}
            </p>
          </div>
        </div>
      </div>

      <hr />

      <div v-if="editable" class="total-row is-flex is-justify-content-space-between">
        <div class="is-bold">Subtotal</div>
        <div class="is-bold">CA${{ formatPrice(totalPrice) }}</div>
      </div>

      <!-- <hr v-if="editable" /> -->

      <!-- <div v-if="editable" class="total-row is-flex is-justify-content-space-between">
        <div class="is-faded">{{ $t("orderDetailsTable.sales-tax") }}</div>
        <div class="is-faded">CA${{ formatPrice(totalTax) }}</div>
      </div> -->

      <hr v-if="editable" />

      <div v-if="editable" class="total-row is-flex is-justify-content-space-between">
        <div class="is-faded">{{ $t("orderDetailsTable.sales-tax") }}</div>
        <div class="is-faded">CA${{ formatPrice(totalTax) }}</div>
      </div>

      <hr v-if="editable" />

      <div class="total-row is-flex is-justify-content-space-between">
        <div class="is-bold">{{ $t("orderDetailsTable.total-due") }}</div>
        <div v-if="editable" class="is-bold">CA${{ formatPrice(totalPrice + totalTax) }}</div>
        <div v-else class="is-bold">CA${{ formatPrice(activeOrder["payment"] / 100) }}</div>
      </div>
    </div>
  </template>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { Loading } from "@/components";
import { AndDeposit } from "@/assets";
import { formatPrice, logError } from "@/utils";
import { ordersAPI } from "@/composables";
import { clientStore, collectionStore } from "@/store";

const props = defineProps({
  quantities: Object,
  editable: {
    type: Boolean,
    default: true,
  },
  activeOrder: {
    type: Object,
    default: null,
  },
  products: {
    type: Array,
    default: [],
  },
  purchaseOrder: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["report"]);

const loading = ref(true);
const totalPrice = ref(0);
const totalTax = ref(0);
const tax_calculation_id = ref("");
const localProducts = ref([]);

onMounted(async () => {
  try {
    await collectionStore.initialize();
    calculateTotals();
    loading.value = false;
  } catch (e) {
    logError(`orderDetailsTable.onMounted: Failed to setup component. ${e.toString()}`);
  }
});

watch(
  () => clientStore.activeClient,
  () => {
    calculateTotals();
  }
);

watch(
  () => collectionStore.products,
  () => {
    calculateTotals();
  }
);

watch(
  () => props.quantities,
  () => {
    calculateTotals();
  }
);

async function calculateTotals() {
  totalPrice.value = 0;
  totalTax.value = 0;
  localProducts.value = [];

  try {
    if (!props.editable) {
      try {
        totalPrice.value = props.activeOrder["priceDetails"]["subTotal"];
        totalTax.value = props.activeOrder["priceDetails"]["taxTotal"];

        for (let product of props.products) {
          if (props.quantities[product.boxIdTag] > 0) {
            localProducts.value.push({
              ...product,
              quantity: props.quantities[product.boxIdTag],
              unitPrice:
                props.activeOrder["priceDetails"][product.boxIdTag]["unitPrice"] /
                product.containersPerCase /
                props.quantities[product.boxIdTag],
              depositPrice:
                props.activeOrder["priceDetails"][product.boxIdTag]["depositPrice"] /
                product.containersPerCase /
                props.quantities[product.boxIdTag],
            });
          }
        }

        emit("report", localProducts.value, totalPrice.value, totalTax.value);
        return;
      } catch (error) {
        console.log(error);
        logError(`orderDetailsTable.calculateTotals: Failed to calculate order totals. ${error.toString()}`);
      }
    }

    try {
      let new_order_totals = await ordersAPI.calculateOrderTotals(
        clientStore.activeClient["data"]["placeID"],
        props.quantities
      );

      localProducts.value = new_order_totals.products;
      totalPrice.value = new_order_totals.sub_total / 100;
      totalTax.value = new_order_totals.total_tax / 100;
      tax_calculation_id.value = new_order_totals.tax_calculation_id;
    } catch (err) {
      logError(`orderDetailsTable.calculateTotals: Failed to calculate order totals. ${err.toString()}`);
    }

    emit("report", localProducts.value, totalPrice.value, totalTax.value);
  } catch (error) {
    logError(`orderDetailsTable.calculateTotals: Failed to calculate order totals. ${error.toString()}`);
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.product-container {
  width: 100%;
  padding: 10px;
  .product-row,
  .deposit-row {
    margin: 0;
    padding: 0;
    .column {
      padding-bottom: 0px;
      padding-top: 0px;
    }
    img {
      max-height: 75px;
      max-width: 75px;
      width: 100%;
      height: 100%;
      padding: 0;
    }
    .name {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .quantity {
      font-size: 0.9rem;
      opacity: 0.9;
    }
  }
}
hr {
  background-color: $denim;
  width: 70%;
  opacity: 0.4;
  border-radius: 6px;

  &.is-full {
    width: 100%;
  }
}
.total-row {
  width: 100%;
  justify-content: space-between;
  div {
    width: fit-content;
  }
  .is-bold {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .is-faded {
    font-size: 1rem;
    opacity: 0.7;
  }
}

.variable-notice {
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  color: $sunrise;
}
</style>
